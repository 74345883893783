
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';

import RatesCompsetMainModel from '@/modules/cluster/models/rates-compset-main.model';
import CompsetScale from '@/modules/common/components/compset-scale.vue';
import Occupancy from '@/modules/common/components/ui-kit/occupancy/index.vue';
import Demand from '@/modules/common/components/ui-kit/demand/index.vue';
import { ASSESSMENT_TYPES } from '@/modules/common/constants';
import Day from '@/modules/common/types/day.type';
import COMPSET_TYPE from '@/modules/compsets/constants/compset-type.constant';
import { PRICE_SHOWN } from '@/modules/rates/constants';
import RatesDocumentModel from '@/modules/rates/models/rates-document.model';

import RatesCommonService, { RatesCommonServiceS } from '@/modules/common/modules/rates/rates-common.service';
import PRICE from '@/modules/common/modules/rates/constants/price.enum';

import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import ClusterDiLiteService, { ClusterDiLiteServiceS } from '@/modules/cluster/cluster-di-lite.service';
import ClusterRatesService, { ClusterRatesServiceS } from '@/modules/cluster/cluster-rates.service';
import ClusterService, { ClusterServiceS } from '@/modules/cluster/cluster.service';
import RatesAllService, { RatesAllServiceS } from '@/modules/rates/rates-all.service';
import RatesAnalysisFiltersService, { RatesAnalysisFiltersServiceS } from '@/modules/rates/rates-analysis-filters.service';
import RatesAnalysisService, { RatesAnalysisServiceS } from '@/modules/rates/rates-analysis.service';
import RatesService, { RatesServiceS } from '@/modules/rates/rates.service';
import RatesPriceHistoryService, { RatesPriceHistoryServiceS } from '../rates-price-history.service';

@Component({
    components: {
        CompsetScale,
        Demand,
        Occupancy,
    },
})
export default class PriceHistoryStatistics extends Vue {
    @Prop({ type: null as any, required: true })
    doc!: RatesDocumentModel | RatesCompsetMainModel;

    @Prop({ type: Number, required: true })
    tableDay!: number;

    @Prop({ type: Boolean, required: true })
    isCluster!: boolean;

    @Prop({ type: String, required: true })
    priceShown!: PRICE_SHOWN;

    @Prop({ type: Boolean, required: true })
    skeleton!: boolean;

    @Prop({ type: Object, required: false })
    chartData!: any;

    @Inject(RatesCommonServiceS)
    private ratesCommonService!: RatesCommonService;

    @Inject(RatesPriceHistoryServiceS)
    private ratesPriceHistoryService!: RatesPriceHistoryService;

    @Inject(RatesAnalysisServiceS)
    private ratesAnalysisService!: RatesAnalysisService;

    @Inject(ClusterRatesServiceS)
    private clusterRatesService!: ClusterRatesService;

    @Inject(RatesServiceS)
    private ratesService!: RatesService;

    @Inject(RatesAllServiceS)
    private ratesAllService!: RatesAllService;

    @Inject(CompsetsServiceS)
    private compsetsService!: CompsetsService;

    @Inject(ClusterServiceS)
    private clusterService!: ClusterService;

    @Inject(ClusterDiLiteServiceS)
    private clusterDiLiteService!: ClusterDiLiteService;

    @Inject(RatesAnalysisFiltersServiceS)
    private ratesAnalysisFiltersService!: RatesAnalysisFiltersService;

    @Inject(HelperServiceS)
    private helperService!: HelperService;

    formatPrice(price: number | null) {
        switch (price) {
            case null:
                return this.$tc('noData');
            case PRICE.SOLD_OUT:
                return this.$tc('soldOut');
            case PRICE.NA:
                return this.$tc('na');
            default:
                return this.currencySymbol(this.doc?.currency || '') + price;
        }
    }

    currencySymbol(rawCurrency: string) {
        return this.helperService.currencySymbol(rawCurrency) || rawCurrency;
    }

    get currentHotelId() {
        return +this.$route.params.hotelId!;
    }

    get day() {
        return +this.$route.params.day! as Day;
    }

    get compsetTypeLabel() {
        if (this.isCluster) {
            const compset = this.clusterService.getMainCompset(this.currentHotelId!);
            return compset?.type || COMPSET_TYPE.MEDIAN;
        }

        const { currentCompset } = this.compsetsService;

        const compsetType = currentCompset
            ? currentCompset.type
            : COMPSET_TYPE.MEDIAN;

        return this.$tc(`compsetRate.${compsetType}`);
    }

    get occupancy() {
        if (this.tableDay === 0) {
            return this.ratesCommonService.getOccupancy(this.day, this.doc as RatesDocumentModel);
        }

        return this.ratesPriceHistoryService.getOccupancy();
    }

    get demand() {
        if (this.tableDay === 0) {
            return this.ratesCommonService.getDemand(this.day, this.doc as RatesDocumentModel);
        }

        return this.ratesPriceHistoryService.getDemand();
    }

    get biggestDifference() {
        if (!this.isAnalysisPage) return null;

        return this.ratesAnalysisService
            .getBiggestComparisonDifference(this.day, this.priceShown);
    }

    get dayColor() {
        let assessment = this.isAnalysisPage
            ? this.biggestDifference!.assessment
            : this.ratesService.getCardAssessment(this.day, this.priceShown);

        if (this.compsetDataType.isNA || this.compsetDataType.isSoldOut || this.compsetDataType.isNoData) {
            return { grey: true };
        }

        if (this.isCluster) {
            assessment = this.clusterRatesService.getCardAssessment(this.day, this.currentHotelId!, this.priceShown);
        }

        if (this.compsetDataType.noComparisonData) {
            return {};
        }

        if (this.isAllChannelsMode) {
            const { currentCompset } = this.compsetsService;
            const diff = this.highestPrice
                ? (this.highestPrice - this.averagePrice!) / this.averagePrice!
                : null;

            assessment = this.isDiLite
                ? this.clusterDiLiteService.getAssessment(this.currentHotelId, this.highestPrice!, this.averagePrice!)
                : this.ratesCommonService.getCardAssessment(diff!, currentCompset!);
        }

        let color = 'grey';

        switch (assessment) {
            case ASSESSMENT_TYPES.GOOD:
                color = 'green';
                break;
            case ASSESSMENT_TYPES.NORMAL:
                color = 'yellow';
                break;
            case ASSESSMENT_TYPES.BAD:
                color = 'red';
                break;
            default: break;
        }

        if (!this.isAnalysisPage && this.compsetDataType.isNA) {
            color = 'red';
        }

        return { [color]: true };
    }

    get compsetDataType() {
        const isNoAverage = !this.averagePrice;

        if (this.isCluster) {
            const { day, currentHotelId: hotelId } = this;

            const isNoData = this.isDiLite
                ? this.clusterDiLiteService.isNoData(day, hotelId!)
                : this.clusterRatesService.isNoData(day, hotelId!);

            return {
                isNA: this.clusterRatesService
                    .isNa(day, hotelId!, undefined, this.priceShown),

                isSoldOut: this.isAllChannelsMode
                    ? !isNoData && isNoAverage
                    : this.clusterRatesService.isSoldOut(day, hotelId!, undefined, this.priceShown),

                isNoData,
            };
        }

        return {
            isNA: this.isAllChannelsMode
                ? false
                : this.isDayNA,
            isSoldOut: this.isAllChannelsMode
                ? isNoAverage
                : this.isDaySoldOut,
            isNoData: this.isDayNoData,
            noComparisonData: this.isAnalysisPage
                ? this.ratesAnalysisService.isNoComparisonData(this.day, this.currentHotelId!)
                : false,
        };
    }

    get averagePrice() {
        if (this.isDiLite) {
            return this.dayData?.average;
        }

        return this.isAllChannelsMode
            ? this.ratesAllService.getAverageRoomsPrice(this.day, this.priceShown)
            : 0;
    }

    get lowestPrice() {
        if (this.isDiLite) {
            return this.dayData?.lowest;
        }

        return !this.compsetDataType.isNoData && !this.compsetDataType.isSoldOut
            ? this.ratesAllService.getLowestPrice(this.day, this.priceShown)
            : 0;
    }

    get highestPrice() {
        if (this.isDiLite) {
            return this.dayData?.highest;
        }

        return !this.compsetDataType.isNoData && !this.compsetDataType.isSoldOut
            ? this.ratesAllService.getHighestPrice(this.day, this.priceShown)
            : 0;
    }

    get competitionPercent() {
        if (this.isCluster) {
            const percent = this.clusterRatesService
                .getCompetitionPercent(this.day, this.currentHotelId!, this.priceShown);

            return percent ? Math.round(percent * 100) : percent;
        }

        const percent = this.isAnalysisPage
            ? this.biggestDifference!.percent
            : this.ratesService.getCompetitionPercent(this.day, undefined, this.priceShown);

        if (percent === null) return 0;

        return Math.round(percent * 100);
    }

    get dayData() {
        if (!this.isDiLite || !this.doc) return null;
        if (this.tableDay !== 0) return null;

        const doc = this.doc as RatesCompsetMainModel;

        return this.clusterDiLiteService
            .getDayData(this.day, doc, this.priceShown);
    }

    get isAllChannelsMode() {
        return this.doc?.providerName === 'all';
    }

    get isAnalysisPage() {
        return this.$route.path.includes('analysis');
    }

    get isDiLite() {
        return this.$route.name!.includes('.di-lite');
    }

    get isChartEmpty() {
        return this.chartData?.datasets
            ? this.chartData.datasets.every((dataset: any) => !dataset.data?.filter(Boolean).length)
            : true;
    }

    get isNoData() {
        const { isNoData } = this.ratesPriceHistoryService;
        const { isChartEmpty } = this;

        return (isNoData || isChartEmpty) && !this.skeleton;
    }

    get isDaySoldOut() {
        const isSoldOut = this.ratesService.isSoldOut(this.day, this.currentHotelId!, this.priceShown);

        if (this.isAnalysisPage) {
            const { comparisonValues } = this.ratesAnalysisFiltersService;

            return isSoldOut || comparisonValues
                .every((_, docIndex) => this.ratesAnalysisService.isSoldOut(this.day, this.currentHotelId!, this.priceShown, docIndex));
        }

        return isSoldOut;
    }

    get isDayNoData() {
        const isNoData = this.ratesService.isNoData(this.day);

        if (this.isAnalysisPage) {
            const { comparisonValues } = this.ratesAnalysisFiltersService;

            return isNoData || comparisonValues
                .every((_, docIndex) => this.ratesAnalysisService.isNoData(this.day, docIndex));
        }

        return isNoData;
    }

    get isDayNA() {
        const isNA = this.ratesService.isNA(this.day, this.currentHotelId!, this.priceShown);

        if (this.isAnalysisPage) {
            const { comparisonValues } = this.ratesAnalysisFiltersService;

            return isNA || comparisonValues
                .every((_, docIndex) => this.ratesAnalysisService.isNA(this.day, this.currentHotelId!, this.priceShown, docIndex));
        }

        return isNA;
    }
}
